import React, { useEffect, useState } from 'react'

// next components
import { useRouter } from 'next/router'

// third party components
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { useDispatch, useSelector } from 'react-redux'
import Lottie from 'lottie-react'
import NotifyAlarmIcon from 'assets/lottery/notify-alarm.json'

// custom components
import SearchBox from 'components/Input/SearchBox'
import ExploreMenu from 'components/Menu/ExploreMenu'
import WalletButton from 'components/Button/WalletButton'
import ProfileMenu from 'components/Menu/ProfileMenu'
import IconButtonWithSVG from 'components/Button/IconButtonWithSVG'

// custom functions
import { useAuthentication } from 'hooks/useAuthentication'

// images and icons
import SearchWhiteIcon from 'assets/icons/search-white.svg?component'
import DarkLightModeIcon from 'assets/icons/dark-light-mode-mobile.svg?component'
import LightDarkModeIcon from 'assets/icons/light-dark-mode-mobile.svg?component'
import FosterNavLogo from 'assets/icons/foster-logo-nav-mobile.svg?component'
import ShoppingCartIcon from 'assets/icons/shoppingcart-icon-navbar.svg?component'
import FosterLogo from 'assets/logo-new.svg?component'

// json
import ExploreMenuList from 'assets/json/explore-menu.json'
import ProfileMenuList from 'assets/json/profile-menu.json'
import { setColorTheme } from 'utils/helpers'

// styles
import styles from './index.module.scss'

// call apis
import searchAPI from 'apis/search'
import userAPI from 'apis/user'
import { getShortAddress } from 'utils/wallet.util'
import NotificationMenu from 'components/Menu/NotificationMenu'
import ShoppingCartMenu from 'components/Menu/ShoppingCartMenu'

const PrimaryHeader = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { setVisible } = useWalletModal()
  const { connected } = useWallet()
  const { profileInfo } = useAuthentication()
  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchList, setSearchList] = useState({ nfts: [], artists: [], collections: [] })
  const [isNewNotification, setIsNewNotification] = useState(false)
  const [isScrollTop, setIsScrollTop] = useState(true)
  const [isScrollingTop, setIsScrollingTop] = useState(true)
  const [lightMode, setLightMode] = useState(useSelector(state => (state as any).lightMode))

  const openShoppingCart = useSelector(state => (state as any).openShoppingCart)
  const openMobileSearchBox = useSelector(state => (state as any).openMobileSearchBox)
  const openMobileMenu = useSelector(state => (state as any).openMobileMenu)
  const markAsRead = useSelector(state => (state as any).markAsRead)

  let timer = null

  let lastScrollPosition = 0

  useEffect(() => {
    const intervalId = setInterval(() => {
      const position = window.pageYOffset

      if (position > 50) setIsScrollTop(false)
      else setIsScrollTop(true)

      if (position < 250) {
        lastScrollPosition = position
        setIsScrollingTop(true)
      } else if (position > lastScrollPosition) {
        lastScrollPosition = position
        setIsScrollingTop(false)
      } else if (position < lastScrollPosition) {
        lastScrollPosition = position
        setIsScrollingTop(true)
      }
    }, 50)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (connected && router.asPath !== '/notifications') {
      userAPI.getNotifications().then(
        response => {
          if (response !== null) {
            if (response.notifications.length > 0) {
              checkNotifications([...response.notifications])
            }
          }
        },
        error => {
          console.log(error)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // update profile menu list
  const [profileMenuList, setProfileMenuList] = useState(ProfileMenuList)
  useEffect(() => {
    if (profileInfo !== null) {
      let array = [...profileMenuList]
      array.forEach(item => {
        if (item.label === 'Profile' && item.path === '/') {
          item.path += profileInfo.username.replaceAll(' ', '%20')
        } else if (item.label === 'My Store' && item.path === '/') {
          item.path += profileInfo.username.replaceAll(' ', '%20') + '/merch'
        }
      })
      setProfileMenuList(array)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInfo])

  useEffect(() => {
    if (searchKeyword !== '' && searchKeyword.length >= 2) {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        setSearchList({ nfts: [], artists: [], collections: [] })
        searchAPI.search(searchKeyword).then(
          response => {
            let object: any = {}
            object.nfts = response.nfts
            object.artists = response.artists
            object.collections = response.collections
            setSearchList(object)
          },
          error => {
            console.log(error)
          }
        )
      }, 500)
    } else {
      setSearchList({ nfts: [], artists: [], collections: [] })
    }
  }, [searchKeyword])

  useEffect(() => {
    if (markAsRead) {
      setIsNewNotification(false)
      dispatch({ type: 'set', markAsRead: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markAsRead])

  async function checkNotifications(notifications) {
    notifications.forEach(notification => {
      if (notification.acknowledged === false) {
        setIsNewNotification(true)
        return
      }
    })
  }

  const handleThemeMode = (changeTo = !lightMode) => {
    setColorTheme(changeTo)
    dispatch({ type: 'set', lightMode: changeTo })
    setLightMode(changeTo)
  }

  const handleClear = () => {
    setSearchKeyword('')
    setSearchList({ nfts: [], artists: [], collections: [] })
  }

  const handleClose = () => {
    setSearchKeyword('')
    setSearchList({ nfts: [], artists: [], collections: [] })
  }

  const handleSelectMenu = (item: any) => {
    if (connected) {
      // router.push(item.path)
      if (item.type === 'collection_nft') {
        dispatch({ type: 'set', mintCollectionStep: 1 })
      }
    } else {
      setVisible(true)
    }
  }

  const handleOpenShoppingCart = () => {
    if (connected) {
      dispatch({ type: 'set', openMobileMenu: false })
      dispatch({ type: 'set', openShoppingCart: !openShoppingCart })
    } else {
      setVisible(true)
    }
  }

  const handleOpenMobileSearchBox = () => {
    dispatch({ type: 'set', openShoppingCart: false })
    dispatch({ type: 'set', openMobileSearchBox: !openMobileSearchBox })
  }

  const handleOpenMobileMenu = () => {
    dispatch({ type: 'set', openShoppingCart: false })
    dispatch({ type: 'set', openMobileMenu: !openMobileMenu })
  }

  const handleGotoHomepage = () => {
    dispatch({ type: 'set', openShoppingCart: false })
    dispatch({ type: 'set', openMobileSearchBox: false })
    dispatch({ type: 'set', openMobileMenu: false })
    router.push('/')
  }

  return (
    <div className="fixed top-0 left-0 z-50 w-full">
      <div
        className={`${styles.blurContainer} ${
          isScrollingTop ? 'top-0' : '-top-28'
        }  justify-center transition-all lg:flex hidden`}
      >
        <nav className="w-full max-w-[1920px] relative">
          <div className="absolute inset-0 w-full flex justify-center items-center">
            <SearchBox
              value={searchKeyword}
              placeholder="Search"
              onChange={value => setSearchKeyword(value)}
              searchList={searchList}
              onClick={() => handleClear()}
              onClose={() => handleClose()}
            />
          </div>

          <div className="w-full h-full flex justify-between g-container">
            <div
              className={`${styles.linkNames} flex lg:gap-[10px] xl:gap-[20px] 2xl:gap-[30px] my-auto items-center w-full max-w-max z-[10001]`}
            >
              <div
                className="flex gap-[6px] cursor-pointer items-center mr-[15px]"
                onClick={() => handleGotoHomepage()}
              >
                <FosterLogo className={styles.logoIcon} />
                <div className={styles.logoFont}>foster</div>
              </div>

              <div className={styles.studioMenu} onClick={() => router.push('/studio')}>
                Studio
              </div>

              <div>
                <ExploreMenu
                  label="Explore"
                  activeList={ExploreMenuList.activeList}
                  filterList={ExploreMenuList.filterList}
                  categoryList={ExploreMenuList.categoryList}
                  performerList={ExploreMenuList.performerList}
                />
              </div>

              <div
                className={styles.studioMenu}
                onClick={() => {
                  if (!connected) {
                    setVisible(true)
                    return
                  }
                  dispatch({ type: 'set', createTab: null })
                  router.push('/create/single-nft')
                }}
              >
                Create
              </div>
            </div>

            <div className="flex items-center my-auto min-w-max z-[10001]">
              <div className="flex">
                {connected && (
                  <>
                    <div className="flex flex-row">
                      <div>
                        <WalletButton />
                      </div>
                      <div>
                        <ShoppingCartMenu />
                      </div>
                      <div>
                        <NotificationMenu />
                      </div>
                    </div>
                  </>
                )}

                {connected && isNewNotification && (
                  <div className="absolute ml-[155px] mt-[-10px]">
                    <Lottie animationData={NotifyAlarmIcon} loop={true} />
                  </div>
                )}

                {connected && (
                  <button className="mr-[20px]" onClick={() => handleThemeMode()}>
                    {lightMode ? <LightDarkModeIcon /> : <DarkLightModeIcon />}
                  </button>
                )}
              </div>

              <div className="min-w-fit">
                <ProfileMenu
                  avatar={profileInfo?.profile_picture}
                  wallet={getShortAddress(profileInfo?.wallet_id)}
                  username={profileInfo?.username ?? ''}
                  menuList={profileMenuList}
                  onSelect={item => router.push(item.path)}
                  connectWalletStatus={connected}
                  lightMode={lightMode}
                  handleThemeMode={handleThemeMode}
                />
              </div>

              {!connected && (
                <button className="ml-7" onClick={() => handleThemeMode()}>
                  {lightMode ? <LightDarkModeIcon /> : <DarkLightModeIcon />}
                </button>
              )}
            </div>
          </div>
        </nav>
      </div>

      <div
        className={`${
          isScrollTop && router.pathname === '/' ? styles.mobileNonBlurContainer : styles.mobileBlurContainer
        } top-0 transition-all block lg:hidden`}
      >
        <div className={styles.mobileContainer}>
          <div className="g-container flex justify-between items-center relative">
            <div className="absolute inset-0 w-full flex justify-center items-center z-[10]">
              <div className="flex gap-[6px] cursor-pointer items-center" onClick={() => handleGotoHomepage()}>
                <FosterLogo className={styles.logoIcon} />
                <div className={styles.logoFont}>foster</div>
              </div>
            </div>

            <div className="flex items-center z-[1000]">
              <div className="w-[39px] h-[39px] flex justify-center items-center">
                <ShoppingCartIcon onClick={() => handleOpenShoppingCart()} />
              </div>
            </div>

            <div className="flex z-[1000]">
              <div className="w-[39px] h-[39px] flex justify-center items-center">
                <SearchWhiteIcon onClick={() => handleOpenMobileSearchBox()} />
              </div>

              <div>
                <IconButtonWithSVG
                  icon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="13.2" viewBox="0 0 22 13.2">
                      <path
                        id="bars-2"
                        d="M3.1,8.2H22.9a1.1,1.1,0,1,0,0-2.2H3.1a1.1,1.1,0,1,0,0,2.2ZM22.9,17H3.1a1.1,1.1,0,0,0,0,2.2H22.9a1.1,1.1,0,1,0,0-2.2Zm0-5.5H3.1a1.1,1.1,0,0,0,0,2.2H22.9a1.1,1.1,0,1,0,0-2.2Z"
                        transform="translate(-2 -6)"
                        fill="var(--mint-btn-bg-1)"
                      />
                    </svg>
                  }
                  onClick={() => handleOpenMobileMenu()}
                  backgroundColor="transparent"
                />
              </div>
            </div>
          </div>
        </div>

        {router.pathname === '/' ? <div className={styles.divider} /> : <div className={styles.divider} />}
      </div>
    </div>
  )
}

export default PrimaryHeader
